import React, {Component} from "react";
import { withTranslation } from "react-i18next";
import '../../style/about.css';


class About extends Component {
    render() { 
        const { t } = this.props; 
        return(
            // About Section 
            <section id="about">
                <div className='about' >
                    <div className="container">
                        <div className="row justify-content" >

                            {/* About Head */}
                            <div className="sentence-container">
                                <div className="side-line"></div>
                                <h2>{t('about.title')}</h2>
                            </div>

                            {/* About Body */}
                            <div className="col-lg-12 " id="about-box">
                                <p className="about-title">
                                    <p>{t('about.content')}</p>
                                </p>
                            </div>
                            
                        </div>
                    </div>           
                </div>

            </section>
        );
    }
}

export default withTranslation()(About); 