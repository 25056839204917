import React, {Component} from "react";
import { withTranslation } from "react-i18next";
import '../../style/style.css';


class Footer extends Component {
    render() {
        const { t } = this.props; 

        const scrollToTop = () => {
            window.scrollTo(0, 0);
        }

    return (

        // Footer
        <footer className="container-fluid" id="footer">
            <div className="card bg-white mx-5"></div>
            <div className="row center">
                <div className="col-md-6 col-sm-6 col-xs-6" id="foot-box">
                    <div className="pull-center">
                        <p> &copy; {t('footer.title')}</p>
                        {/* <a href="https://mohammed-ahmed.netlify.app/" target="_blank">Mohamed Ahmed</a> */}
                    </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-6" id="foot-box">
                    <div className="pull-right mr-4 policy">
                        <div>

                            <a href="#top" class="top" onClick={scrollToTop}>
                                <p class="scroll">{t('footer.title1')} <span>{t('footer.title2')}</span></p>
                                <i id="up" class="fa-solid fa-angles-up"></i>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </footer>

        );
    }
}


export default withTranslation()(Footer); 