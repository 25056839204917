import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "../../style/team.css";
import Amr from "../../assets/img/amr.webp";
import Alina from "../../assets/img/Alina.webp";
import Hani from "../../assets/img/Hani.webp";
import Myriam from "../../assets/img/Myriam.webp";
import Feng from "../../assets/img/feng.webp";
import Xiang from "../../assets/img/xiang.webp";
import Max from "../../assets/img/m.webp";
import Jes from "../../assets/img/j.webp";
import Ayman from "../../assets/img/ayman.webp";
import Basel from "../../assets/img/basel.webp";
import a from "../../assets/img/a.webp";
import b from "../../assets/img/b.webp";
import c from "../../assets/img/c.webp";
import d from "../../assets/img/d.webp";
import e from "../../assets/img/e.webp";
import f from "../../assets/img/f.webp";
import g from "../../assets/img/g.webp";
import h from "../../assets/img/h.webp";

class Team extends Component {
    render() {
        const { t } = this.props; 
        return (
            // Team Section
            <section id="team">
                <div className="team">
                    <div className="container mx-auto">

                        {/* Team Head */}
                        <div class="sentence-container">
                            <div class="side-line"></div>
                            <h2> {t('team.title')}</h2>
                        </div>

                        {/* Team Members */}
                        <div className="row justify-content-center" id="team-card">

                            {/* Team One */}
                            <div className="row justify-content-center">
                                {/* Team Title */}
                                <div className="team-title">
                                        <h3 className="team-title1">{t('team.title1Box1')}</h3>
                                        <p className="team-p">{t('team.title2Box1')} <br />
                                            {t('team.title3Box1')}
                                        </p>
                                        {/* <div class="line"></div> */}
                                </div>

                                {/* Egypt Team */}
                                <div className="card col-md-6 col-lg-4 mb-5 " id="card-team">
                                    <div className="card-link">
                                        <div>
                                            <img src="https://img.icons8.com/color/35/egypt-circular.png" alt="egypt"/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={Amr} className="card-img-top" alt="Member-Img" />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-name">
                                            <h3>Amr Elmenoufy</h3>
                                        </div>
                                        <p className="card-text mb-0">總經理</p>
                                        <p className="card-text">General Manager</p>
                                    </div>
                                </div>
        
                                {/* Taiwan Team */}
                                <div className="card col-md-6 col-lg-4 mb-5 " id="card-team">
                                    <div className="card-link">
                                        <div>
                                            <img src="https://img.icons8.com/color/35/taiwan-flag-circle.png" alt="taiwan-emoji"/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={Max} className="card-img-top" alt="Member-Img" />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-name">
                                            <h3>Max Wang</h3>
                                        </div>
                                        <p className="card-text mb-0">金融服務專家</p>
                                        <p className="card-text">Financial Services Expert</p>
                                    </div>
                                </div>

                                {/* Taiwan Team */}
                                <div className="card col-md-6 col-lg-4 mb-5 " id="card-team">
                                    <div className="card-link">
                                        <div>
                                            <img src="https://img.icons8.com/color/35/taiwan-flag-circle.png" alt="taiwan-emoji"/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={Alina} className="card-img-top" alt="Member-Img" />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-name">
                                            <h3>Alina Liu</h3>
                                        </div>
                                        <p className="card-text mb-0">行政秘書</p>
                                        <p className="card-text">Chief Financial Officer</p>
                                    </div>
                                </div>

                                {/* Egypt Team */}
                                <div className="card col-md-6 col-lg-4 mb-5 " id="card-team">
                                    <div className="card-link">
                                        <div>
                                            <img src="https://img.icons8.com/color/35/egypt-circular.png" alt="egypt"/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={Hani} className="card-img-top" alt="Member-Img" />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-name">
                                            <h3>Hani Fakhry</h3>
                                        </div>
                                        <p className="card-text mb-0">營運經理</p>
                                        <p className="card-text">Operations Manager</p>
                                    </div>
                                </div>

                                {/* Taiwan Team */}
                                <div className="card col-md-6 col-lg-4 mb-5 " id="card-team">
                                    <div className="card-link">
                                        <div>
                                            <img src="https://img.icons8.com/color/35/taiwan-flag-circle.png" alt="taiwan-emoji"/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={Feng} className="card-img-top" alt="Member-Img" />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-name">
                                            <h3>Daniel Liu</h3>
                                        </div>
                                        <p className="card-text mb-0">供應鏈專家</p>
                                        <p className="card-text">Supply Chain Expert</p>
                                    </div>
                                </div>

                                {/* Taiwan Team */}
                                <div className="card col-md-6 col-lg-4 mb-5 " id="card-team">
                                    <div className="card-link">
                                        <div>
                                            <img src="https://img.icons8.com/color/35/taiwan-flag-circle.png" alt="taiwan-emoji"/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={Xiang} className="card-img-top" alt="Member-Img" />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-name">
                                            <h3>Ivy Tsai</h3>
                                        </div>
                                        <p className="card-text mb-0">金融服務專家</p>
                                        <p className="card-text">Financial Services Expert</p>
                                    </div>
                                </div>

                                {/* Morocco Team */}
                                <div className="card col-md-6 col-lg-4 mb-5 " id="card-team">
                                    <div className="card-link">
                                        <div>
                                            <img src="https://img.icons8.com/color/35/morocco-circular.png" alt="morocco-emoji"/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={Myriam} className="card-img-top" alt="Member-Img" />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-name">
                                                <h3>Myriam Lahsan</h3>
                                        </div>
                                        <p className="card-text mb-0">行銷總監</p>
                                        <p className="card-text">Marketing Director</p>
                                    </div>
                                </div>

                                {/* Taiwan Team */}
                                <div className="card col-md-6 col-lg-4 mb-5 " id="card-team">
                                    <div className="card-link">
                                        <div>
                                            <img src="https://img.icons8.com/color/35/taiwan-flag-circle.png" alt="taiwan-emoji"/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={Jes} className="card-img-top" alt="Member-Img" />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-name">
                                            <h3>Jessica Lee</h3>
                                        </div>
                                        <p className="card-text mb-0">銷售經理</p>
                                        <p className="card-text">Sales Manager</p>
                                    </div>
                                </div>
                                
                            </div>

                            {/* Team Two */}
                            <div className="row justify-space-evenly mb-4" id="team-two">
                                {/* Team Title */}
                                <div className="team-title">
                                        <h3 className="team-title1">{t('team.title1Box2')}</h3>
                                        <p className="team-p">{t('team.title2Box2')} <br />
                                            {t('team.title3Box2')} <br />
                                            <a href="http://ABAvantage.com" target="_blank" rel="noreferrer" class="hover-effect">{t('team.title4Box2')} →</a>
                                        </p>
                                </div>

                                {/* Egypt Team*/}
                                <div className="card col-md-6 col-lg-4 mb-5 " id="card-team">
                                    <div className="card-link">
                                        <div>
                                            <img src="https://img.icons8.com/color/35/egypt-circular.png" alt="egypt"/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={Ayman} className="card-img-top" alt="Member-Img" />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-name">
                                            <h3>Ayman Elkasaby</h3>
                                        </div>
                                        <p className="card-text mb-0">金融服務專家</p>
                                        <p className="card-text">Financial Services Expert</p>
                                    </div>
                                </div>

                                {/* Egypt Team */}
                                <div className="card col-md-6 col-lg-4 mb-5 " id="card-team">
                                    <div className="card-link">
                                        <div>
                                            <img src="https://img.icons8.com/color/35/egypt-circular.png" alt="egypt"/>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <img src={Basel} className="card-img-top" alt="Member-Img" />
                                    </div>
                                    <div className="card-body">
                                        <div className="card-name">
                                            <h3>Bassel Riad</h3>
                                        </div>
                                        <p className="card-text mb-0">業務和數據專家</p>
                                        <p className="card-text">Business & Data Expert</p>
                                    </div>
                                </div>
                            </div>

                            {/* Team Images */}
                            <div className="row justify-content-center">
                            <div id="carouselExample" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src={a} className="d-block" alt="Team-Images" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={b} className="d-block" alt="Team-Images" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={c} className="d-block" alt="Team-Images" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={e} className="d-block" alt="Team-Images" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={f} className="d-block" alt="Team-Images" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={g} className="d-block" alt="Team-Images" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={h} className="d-block" alt="Team-Images" />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={d} className="d-block" alt="Team-Images" />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>    
        );
    }
}

export default withTranslation()(Team);
