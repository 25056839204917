import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import LanguageSwitcher from './LanguageSwitcher';
// import LanguageDropdown from "./lang";
import "../../style/style.css";
import Logo from '../../assets/img/logo.webp';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
      isBlurred: false,
    };
  }

  handleToggleOffcanvas = () => {
    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
      isBlurred: !prevState.isVisible,
    }));
  };
  
  handleHideOffcanvas = () => {
    this.setState({ isVisible: false, isBlurred: false });
  };

  render() {
    const { isVisible, isBlurred } = this.state;

    const scrollToTop = () => {
      window.scrollTo(0, 0);
    }

    const { t } = this.props; 

    return (
      // Header
      <header>
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">

            <a className="navbar-brand" href="#Top" onClick={scrollToTop}>
              <div className="logo-item">
                  <div className="icon-box">
                      <img src={Logo} alt="Navbar Logo" />
                  </div>
                  <div className="logo-info" id="logo-title">
                      <p className="logo-title">英格國際貿易有限公司</p>
                      <p className="logo-link">INGlobe International Co., Ltd</p>
                  </div>
              </div>
            </a>

            <button className={`navbar-toggler ${isVisible ? "active" : ""}`} type="button" onClick={this.handleToggleOffcanvas} aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
              <i className="fa-solid fa-bars-staggered"></i>
            </button>

            <div className={`offcanvas offcanvas-end ${isVisible ? 'show' : ''}`} tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">

              <div className="offcanvas-header">
                <button className="button1" onClick={this.handleHideOffcanvas} aria-label="Close">
                  <i id="close" class="fa-solid fa-xmark"></i>
                </button>
              </div>

              <div className="offcanvas-body">
                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3" onClick={() => { this.handleHideOffcanvas(); this.setState({ isBlurred: false }); }}>  

                  <li className="nav-item">
                    <a className="nav-link" href="#Top" onClick={scrollToTop}>
                      <h5> {t('navbar.nav1')}</h5>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="#about">
                      <h5>{t('navbar.nav2')}</h5>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="#service">
                      <h5>{t('navbar.nav3')}</h5>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="#team">
                      <h5>{t('navbar.nav4')}</h5>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="#office">
                      <h5> {t('navbar.nav5')}</h5>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="#project">
                      <h5> {t('navbar.nav6')}</h5>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" href="#contact">
                      <h5> {t('navbar.nav7')}</h5>
                    </a>
                  </li>

                  <li className="nav-item">
                    <a className="nav-link" >
                      <h5> <LanguageSwitcher /></h5>
                    </a>
                  </li> 

                </ul>
                
                {/* <LanguageSwitcher /> */}

              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default withTranslation()(Navbar);
