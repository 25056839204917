import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState('zh');

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setCurrentLanguage(lang);
    };

    return (
        <div>
            {(currentLanguage === 'zh') && (
                <div className="dropdown-list">
                    <button className="language-button" onClick={() => changeLanguage('en')}>
                        <img width="28" height="28" src="https://img.icons8.com/color/48/great-britain-circular.png" alt="great-britain-circular"/>
                        EN
                    </button>
                </div>
            )}
            {(currentLanguage === 'en') && (
                <div className="dropdown-list">
                    <button className="language-button" onClick={() => changeLanguage('zh')}>
                        <img width="28" height="28" src="https://img.icons8.com/color/24/taiwan-flag-circle.png" alt="taiwan-circular"/>
                        台灣
                    </button>
                </div>
            )}
        </div>
    );
};

export default LanguageSwitcher;






























// // LanguageSwitcher.js
// import React from 'react';
// import { useTranslation } from 'react-i18next';

// const LanguageSwitcher = () => {
//     const { i18n } = useTranslation();

//     const changeLanguage = (lang) => {
//         i18n.changeLanguage(lang);
//     };

//     return (
//         // <div>
//         //     <button onClick={() => changeLanguage('en')}>English</button>
//         //     <button onClick={() => changeLanguage('zh')}>中文</button>
//         // </div>

//         <div className="dropdown">
//             <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
//                 <img width="35" height="35" src="https://img.icons8.com/dusk/64/globe--v1.png" alt="globe--v1"/>
//             </button>
//             <div className="dropdown-menu">
//                 <div className="dropdown-list">
//                     <button className="language-button" onClick={() => changeLanguage('en')}>
//                     <img width="24" height="24" src="https://img.icons8.com/color/48/great-britain-circular.png" alt="great-britain-circular"/>
//                         En
//                     </button>
//                 </div> 
//                 <hr/>
//                 <div className="dropdown-list">
//                     <button className="language-button" onClick={() => changeLanguage('zh')}>
//                     <img width="24" height="24" src="https://img.icons8.com/color/48/china-circular.png" alt="china-circular"/>
//                         中文
//                     </button>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default LanguageSwitcher;
