import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "../../style/contact.css";
import Egy from "../../assets/img/egy.webp";
import Twi from "../../assets/img/twi.webp";


class Office extends Component  {
    render() {
        const { t } = this.props; 
        return (
            // office Section
            <section id="office">
                <div className="office-section" >
                    <div className="container mx-auto">

                        {/* Offices Head */}
                        <div className="sentence-container">
                            <div className="side-line"></div>
                            <h2>{t('office.title')}</h2>
                        </div>

                        {/* Offices Card */}
                        <div className="row" id="cards-office">

                            {/* Office One */}
                            <div className="card col-md-6 col-lg-4 mb-5 p-4" id="card-office">
                                <div className="card-head">
                                    <h4>{t('office.titleBox1')}</h4>
                                </div>
                                <div className="card-body">
                                    <img src={Twi}  className="card-img-top" alt="Offices-Img" />
                                </div>
                                <div className="card-foot">
                                    <div>{t('office.content1Box1')}</div>
                                    <p>{t('office.content2Box1')}</p>
                                </div>
                            </div>
        
                            {/* Office Two */}
                            <div className="card col-md-6 col-lg-4 mb-5 p-4" id="card-office">
                                <a href="http://ABAvantage.com" target="_blank" rel="noreferrer">
                                    <div className="card-head">
                                        <h4>{t('office.titleBox2')}</h4>
                                    </div>
                                    <div className="card-body">
                                        <img src={Egy} className="card-img-top" alt="Offices-Img" />
                                    </div>
                                    <div className="card-foot">
                                        <div>{t('office.content1Box2')}</div>
                                        <p>{t('office.content2Box2')}</p>
                                    </div>
                                    </a>
                            </div>
                            
                        </div>

                    </div>
                </div>
            </section>    
        );
    }
}

export default withTranslation()(Office);