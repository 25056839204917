import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { useTranslation } from 'react-i18next';

const MyComponent = () => {
  const { t } = useTranslation();

  const [text] = useTypewriter({
    words: [t('home.title1')], // استخدام ترجمة للجملة
    loop: true,
    typeSpeed: 50,
    deleteSpeed: 30,
    delaySpeed: 1500,
    onLoopDone: () => console.log(`loop completed after 3 runs.`),
  });

  return text;
};

export default MyComponent;
