import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import "../../style/service.css";

class Service extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFullText1: false,
            showFullText2: false,
            showFullText3: false,
        };
    }

    toggleText1 = () => {
        this.setState((prevState) => ({
            showFullText1: !prevState.showFullText1,
        }));
    };

    toggleText2 = () => {
        this.setState((prevState) => ({
            showFullText2: !prevState.showFullText2,
        }));
    };

    toggleText3 = () => {
        this.setState((prevState) => ({
            showFullText3: !prevState.showFullText3,
        }));
    };

    render() {
        const { showFullText1, showFullText2, showFullText3 } = this.state;
        const { t } = this.props;

        return (
            <section id="service">
                <div className="services-section">
                    <div className="container mx-auto">
                        {/* Service Head */}
                        <div className="sentence-container">
                            <div className="side-line"></div>
                            <h2> {t('services.title')}</h2>
                        </div>

                        {/* Services Box Section */}
                        <div className="services-box">
                            {/* Card One */}
                            <div className="services-card">
                                <div className="overflow">
                                    <p className="services-title text-center">
                                        {t('services.title1')}
                                    </p>
                                    <div>
                                        {showFullText1 ? (
                                            <p className="services-desc">
                                                {t('services.content1Box1')}{' '}
                                                <br />
                                                {t('services.content1Box2')}{' '}
                                                <br />
                                                {t('services.content1Box3')}{' '}
                                                <br />
                                                {t('services.content1Box4')}
                                            </p>
                                        ) : (
                                            <p className="services-desc"></p>
                                        )}

                                        <div className="btn-box">
                                            <button
                                                onClick={this.toggleText1}
                                                className="btn"
                                            >
                                                {showFullText1
                                                    ? t('serviceBtn.showLess')
                                                    : t('serviceBtn.showMore')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Card Two */}
                            <div className="services-card">
                                <div className="overflow">
                                    <p className="services-title text-center">
                                        {t('services.title2')}
                                    </p>
                                    <div>
                                        {showFullText3 ? (
                                            <p className="services-desc">
                                                {t('services.content2Box1')}
                                                <br />
                                                {t('services.content2Box2')}
                                                <br />
                                                {t('services.content2Box3')}
                                                <br />
                                                {t('services.content2Box4')}
                                            </p>
                                        ) : (
                                            <p className="services-desc"></p>
                                        )}

                                        <div className="btn-box">
                                            <button
                                                onClick={this.toggleText3}
                                                className="btn"
                                            >
                                                {showFullText3
                                                    ? t('serviceBtn.showLess')
                                                    : t('serviceBtn.showMore')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Card Three */}
                            <div className="services-card">
                                <div className="overflow">
                                    <p className="services-title text-center">
                                        {t('services.title3')}
                                    </p>
                                    <div>
                                        {showFullText2 ? (
                                            <p className="services-desc">
                                                {t('services.content3Box1')}{' '}
                                                <br />
                                                {t('services.content3Box2')}{' '}
                                                <br />
                                                {t('services.content3Box3')}{' '}
                                                <br />
                                                {t('services.content3Box4')}
                                            </p>
                                        ) : (
                                            <p className="services-desc"></p>
                                        )}

                                        <div className="btn-box">
                                            <button
                                                onClick={this.toggleText2}
                                                className="btn"
                                            >
                                                {showFullText2
                                                    ? t('serviceBtn.showLess')
                                                    : t('serviceBtn.showMore')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withTranslation()(Service);
