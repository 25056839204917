import React, {Component} from "react";
import { withTranslation } from "react-i18next";

import '../../style/style.css';
import AboutLogo from "../../assets/img/logo.webp"
import WordFlick from "./WordFlick";


class Home extends Component {
    render() {
        const { t } = this.props; 
        return(
            // Home Section
            <section id="home">
                <div className='home'>
                    <div className="container" >
                        <div className="row justify-content-center" >

                            {/* Home Title */}
                            
                            <div class="col-lg-10 " id="title" >
                                
                                <h1 className="name">
                                    <img className="img" src={AboutLogo} alt="Home-Img" /> 
                                    <div className="home-info" id="home-title">
                                        <p className="home-title">英格國際貿易有限公司</p>
                                        <p className="home-link">INGlobe International Co., Ltd</p>
                                    </div>
                                </h1>

                                <h2 className=" font-bold text-2xl dark:text-slate-50 ">
                                    <WordFlick />
                                    <span>|</span>{" "}
                                </h2>

                                {/* Body */}
                                <h3 className="title">{t('home.title2')}</h3>

                                {/* Contact Btn */}
                                <a href="#contact">
                                    <button className="button">
                                        {t('home.contactBtn')} &nbsp; <i class="fa-solid fa-phone fa-rotate-270"></i>
                                    </button>
                                </a>  
                            </div>

                        </div>  
                    </div>
                </div>
            </section>    
        );
    }
}

export default withTranslation()(Home); 